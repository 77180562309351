.ngx-datatable.material {
  box-shadow: none;
  background-color: transparent;

  .datatable-body-cell-label {
    display: flex;
  }

  .datatable-row-wrapper {
    cursor: pointer;

    .ico-more-options {
      opacity: 1;
    }
  }

  .ico-more-options {
    top: 6px;
    position: absolute;
    right: 20px;
  }

  @media (max-width: 959px) {
    .ico-more-options {
      opacity: 1;
    }
  }

  &.line-divider {
    .datatable-row-wrapper {
      border-bottom: 1px solid #e4e4e4;
    }
  }

  &.single-selection,
  &.multi-selection,
  &.multi-click-selection {
    .datatable-body-row {
      cursor: pointer;

      &.active,
      &.active .datatable-row-group {
        background-color: transparent !important;
        color: #FFF;
      }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: #EEE;
        color: #FFF;
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: transparent;
        color: #FFF;
      }
    }
  }

  .datatable-body {
    .datatable-row-detail {
      cursor: default;
      background: #ffffff;
      box-shadow: 0 4px 3px rgba(1, 1, 1, .2);
      padding: 0 20px;
    }
  }
}

.ngx-datatable.material {
  .centered {
    text-align: center !important;
  }

  .centered .datatable-body-cell-label {
    align-items: center;
    justify-content: center;
  }
}

.datatable-fill {
  height: calc(100vh - 130px);
}

.ngx-datatable.material.no-pointer {
  .datatable-row-wrapper {
    cursor: text;
  }
}

datatable-body-cell:first-child .mat-mdc-checkbox {
  margin: -8px;
}
