@import "variables";

body {
  font-family: $baseFontFamily;
  font-weight: normal;
  font-size: $baseFontSize;
}

h1, h2, h3, h4 {
  font-weight: normal;
}

*,
:after,
:before {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
}
