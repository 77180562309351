@use '@angular/material' as mat;
@import '../styles/theme/variables-theme';

.spinner {
  width: 40px;
  height: 40px;
  position: absolute;
  margin: -20px 0 0 -20px;
  top: 50%;
  left: 50%;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: mat.get-color-from-palette($primary);
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
