/* --- List views ---- */
.mat-mdc-card.toolbar {
  display: flex;
  margin-bottom: 16px;
}

.toolbar > *:nth-child(1),
.toolbar-filter > *:nth-child(1) {
  width: 80%;
}

.toolbar-filter {
  display: flex;
}

.toolbar-more {
  text-align: right;
  padding-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}

/* --- Manager views ---- */
.manager-form .mat-mdc-card:first-child {
  padding: 0;
}

.manager-form .mat-mdc-card {
  margin-bottom: 16px;
}

.manager-form .mat-mdc-card:first-child .mat-mdc-card-content {
  padding: 16px;
}

.icon-msg mat-icon {
  vertical-align: middle;
  margin: 0 5px 5px 5px;
}

.manager-form .mat-mdc-toolbar .mdc-button {
  margin-left: 15px;
}

/* Block manipulation */
.fill-remaining {
  flex: 1 1 auto;
}
