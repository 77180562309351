@use '@angular/material' as mat;
@import '../theme/variables-theme';

.mat-mdc-drawer-container {
  background-color: #eee !important;
}

.mat-mdc-form-field {
  .mdc-text-field {
    padding: 0 6px;

    .mat-mdc-form-field-infix {
      min-height: 44px;
    }
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) {
    .mat-mdc-form-field-infix {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.mat-button-toggle-group {
  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($primary, 200);
  }
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-item[disabled] {
    opacity: 1;
  }
}

.mat-mdc-dialog-surface {
  padding: 24px;
}

.mat-mdc-card {
  .mat-mdc-card-content {
    padding: 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

//.mat-mdc-option {
.mat-mdc-select-panel {
  .mat-mdc-option,
  .mat-mdc-optgroup-label {
    min-height: 40px;

    .mdc-list-item__primary-text {
      line-height: 1.5em;
    }
  }
}

.cdk-overlay-pane {
  width: auto !important;
}

.mdc-button.mat-mdc-button > .mat-icon {
  font-size: 1.3rem;
  height: 1.3rem;
  width: 1.3rem;
}

.mat-mdc-form-field .mdc-text-field:has(.mdc-slider--discrete) {
  overflow: visible;
}

.mat-mdc-slider.mdc-slider--discrete:not(.mdc-slider--disabled) {
  .mdc-slider__thumb .mdc-slider__value-indicator-container {
    width: inherit;

    .mdc-slider__value-indicator {
      transform: scale(1);
      opacity: 0.9;
      height: 28px;
      padding: 0;
      justify-content: center;
    }
  }
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
  .mat-mdc-form-field-infix {
  padding-bottom: 0px;
}
