@use '@angular/material' as mat;
@import './variables-theme';

.badge.active {
  background-color: mat.get-color-from-palette($primary);
}

.badge.inactive {
  background-color: grey;
}

.badge.terminating {
  background-color: mat.get-color-from-palette($accent);
}

.badge.error {
  background-color: mat.get-color-from-palette($warn);
}

.badge.completed {
  background-color: $success;
}
