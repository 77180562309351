.header-overlay {
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.6;
  position: fixed;
  z-index: 4;
}

.header-overlay-item {
  z-index: 4;
}
