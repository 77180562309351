@import './variables';
@import './fonts';
@import './vendors/vendors';
@import './reset';
@import './layout';
@import './overlay';
@import './loader';
@import './theme/theme';
@import './overloads/overloads';

@import '../app/atoms/overlay-button/overlay-button.directive.scss';

.mat-mdc-button-focus-overlay {
  background-color: transparent !important;
}
