@use '@angular/material' as mat;
@import "../variables";

$primary: mat.define-palette(mat.$deep-purple-palette);
$accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$warn: mat.define-palette(mat.$red-palette, 500);
$success: map-get(mat.$green-palette, 500);

$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  ),
  typography: mat.define-typography-config(
    $font-family: $baseFontFamily,
    $body-1: mat.define-typography-level(
      $font-weight: 400,
      $font-size: $baseFontSize,
      $letter-spacing: inherit,
    ),
    $button: mat.define-typography-level(
      $font-weight: 400,
      $font-size: $baseFontSize,
      $letter-spacing: inherit,
    ),
  ),
  density: -1,
));
