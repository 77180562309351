.layout-center {
  margin: auto;
}

.form-block {
  padding: 24px;
}

// filters
.sidenav-filter {
  width: 400px;
}

.mat-drawer-content {
  background-color: white;
}

.badge {
  padding: 0 6px;
  text-align: center;
  line-height: 22px;
  height: 22px;
  box-sizing: border-box;
  border-radius: 2px;
  color: #fff;
  font-weight: 300;
  font-size: 0.8rem;
}
