/* -- CALENDAR PICKER -> div.input-timestamp --*/
.input-timestamp {
  position: relative;
  margin-top: -5px;
}

.input-timestamp:focus {
  outline: none;
}

.input-timestamp > label {
  color: rgba(0, 0, 0, .38);
  font-size: 11px;
}

.input-timestamp .p-calendar .p-inputtext {
  font-size: 14px;
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  border: none;
  border-bottom: rgba(0, 0, 0, .42) solid 1px;
  outline: none;
  width: 100%;
  padding: 0 0 5px 2px;
  margin-top: 18px;
}

.input-timestamp-error {
  color: red;
}

.input-timestamp p-calendar input:focus {
  box-shadow: none;
}

.input-timestamp p-calendar > * {
  width: 100%;
  display: block;
}

.input-timestamp .p-datepicker table td > span {
  height: 1.8rem;
  width: 1.8rem;
}

.input-timestamp .p-calendar .p-datepicker {
  min-width: auto;
  top: inherit !important;
}
