json-editor div.jsoneditor,
jarvis-json-editor div.jsoneditor {
  background-color: #FFF;
}

.jsoneditor-outer > div.jsoneditor-tree {
  min-height: 200px;
}

div.jsoneditor-menu a.jsoneditor-poweredBy {
  display: none !important;
}

.toolbar-filter-chips .mat-mdc-input-wrapper {
  margin: 0;
}
